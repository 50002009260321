// Generated by Framer (edb532b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["dIkQTofja", "QgE54BcTc"];

const serializationHash = "framer-cMZQ7"

const variantClassNames = {dIkQTofja: "framer-v-1paai2z", QgE54BcTc: "framer-v-mp3fcy"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 30, delay: 0, mass: 1, stiffness: 400, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Menu: "dIkQTofja", X: "QgE54BcTc"}

const getProps = ({color, height, id, tap, width, ...props}) => { return {...props, mP9Tz30oK: color ?? props.mP9Tz30oK ?? "var(--token-e0ce79e8-f023-49ad-9edf-bfcca13d71cf, rgb(7, 15, 75))", TBgHLr7MW: tap ?? props.TBgHLr7MW, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "dIkQTofja"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any;color?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, TBgHLr7MW, mP9Tz30oK, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "dIkQTofja", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap13atowq = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (TBgHLr7MW) {const res = await TBgHLr7MW(...args);
if (res === false) return false;}
setVariant("QgE54BcTc")
})

const onTap13dpj77 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (TBgHLr7MW) {const res = await TBgHLr7MW(...args);
if (res === false) return false;}
setVariant("dIkQTofja")
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1paai2z", className, classNames)} data-framer-name={"Menu"} data-highlight layoutDependency={layoutDependency} layoutId={"dIkQTofja"} onTap={onTap13atowq} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({QgE54BcTc: {"data-framer-name": "X", onTap: onTap13dpj77}}, baseVariant, gestureVariant)}><motion.div className={"framer-1nr8ri5"} data-framer-name={"Bottom Line"} layoutDependency={layoutDependency} layoutId={"vnNA_eUbM"} style={{backgroundColor: mP9Tz30oK, rotate: 0}} variants={{QgE54BcTc: {rotate: -45}}}/><motion.div className={"framer-1fdeuas"} data-framer-name={"Middle Line"} layoutDependency={layoutDependency} layoutId={"gZ9JPcMzk"} style={{backgroundColor: mP9Tz30oK, opacity: 1}} variants={{QgE54BcTc: {opacity: 0}}}/><motion.div className={"framer-daqzsb"} data-framer-name={"Top Line"} layoutDependency={layoutDependency} layoutId={"ZoL8InGJ4"} style={{backgroundColor: mP9Tz30oK, rotate: 0}} variants={{QgE54BcTc: {rotate: 45}}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-cMZQ7.framer-6pjyry, .framer-cMZQ7 .framer-6pjyry { display: block; }", ".framer-cMZQ7.framer-1paai2z { cursor: pointer; height: 32px; overflow: hidden; position: relative; width: 32px; }", ".framer-cMZQ7 .framer-1nr8ri5 { bottom: 7px; flex: none; height: 2px; left: calc(50.00000000000002% - 24px / 2); overflow: hidden; position: absolute; width: 24px; }", ".framer-cMZQ7 .framer-1fdeuas { flex: none; height: 2px; left: calc(50.00000000000002% - 24px / 2); overflow: hidden; position: absolute; top: calc(50.00000000000002% - 2px / 2); width: 24px; }", ".framer-cMZQ7 .framer-daqzsb { flex: none; height: 2px; left: calc(50.00000000000002% - 24px / 2); overflow: hidden; position: absolute; top: 7px; width: 24px; }", ".framer-cMZQ7.framer-v-mp3fcy.framer-1paai2z { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 32px); }", ".framer-cMZQ7.framer-v-mp3fcy .framer-1nr8ri5 { bottom: unset; top: calc(50.00000000000002% - 2px / 2); }", ".framer-cMZQ7.framer-v-mp3fcy .framer-1fdeuas { left: calc(50.00000000000002% - 2px / 2); width: 2px; }", ".framer-cMZQ7.framer-v-mp3fcy .framer-daqzsb { left: unset; right: 4px; top: calc(50.00000000000002% - 2px / 2); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"QgE54BcTc":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"TBgHLr7MW":"tap","mP9Tz30oK":"color"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerGh7RDcq7i: React.ComponentType<Props> = withCSS(Component, css, "framer-cMZQ7") as typeof Component;
export default FramerGh7RDcq7i;

FramerGh7RDcq7i.displayName = "Menu Icon";

FramerGh7RDcq7i.defaultProps = {height: 32, width: 32};

addPropertyControls(FramerGh7RDcq7i, {variant: {options: ["dIkQTofja", "QgE54BcTc"], optionTitles: ["Menu", "X"], title: "Variant", type: ControlType.Enum}, TBgHLr7MW: {title: "Tap", type: ControlType.EventHandler}, mP9Tz30oK: {defaultValue: "var(--token-e0ce79e8-f023-49ad-9edf-bfcca13d71cf, rgb(7, 15, 75)) /* {\"name\":\"Background - Dark\"} */", title: "Color", type: ControlType.Color}} as any)

addFonts(FramerGh7RDcq7i, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})